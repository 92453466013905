import React from 'react'
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import {DeviceIconWithLabel} from './DefaultDevices';

export default function SwitchContact({device}) {  
  const off = device.Data === "Closed" || device.Data === "Locked";
  return( 
    <DeviceIconWithLabel 
      Icon={off ? LockIcon : LockOpenIcon}
      iconColor={off ? "disabled" : "secondary" }
      tooltip={device.LastUpdate}
     >{device.Name}</DeviceIconWithLabel>
  );
}