import React, {useState} from 'react';
import { makeStyles } from '@mui/styles';
import Collapse from '@mui/material/Collapse';
import FavoriteIcon from '@mui/icons-material/Favorite';
import VideocamIcon from '@mui/icons-material/Videocam';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import {TOOLBAR_HEIGHT, VERSION} from './Constants';

const useStyles = makeStyles(theme => ({
  appBar: { top: 'auto !important', bottom: 0 },
}));

export default function AppTabToolbar({showCameraTab, loggedIn, changingOrder, handleMenuItemClick, tab, setTab}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuLoaded, setMenuLoaded] = useState(false);
  const classes = useStyles();
  const menuOpen = Boolean(anchorEl);

  const handleTabChange = (event, value) => {
    setTab(value);
  };

  function handleMenuClick(event) {
    setAnchorEl(event.currentTarget);
  };

  function handleMenuClose() {
    setAnchorEl(null);
  };

  function handleMenuEnter() {
    setMenuLoaded(true);
  };
  
  function handleMenuExit() {
    setMenuLoaded(false);
  };
  
  function thisHandleMenuItemClick(event) {
    handleMenuClose();
    handleMenuItemClick(event);
  };

  if(!loggedIn) {
    return null;
  }
  return (
    <Paper elevation={0} sx={{ boxShadow: 2, position: 'fixed', height: '56px', bottom: 0, left: 0, right: 0}}>
      <BottomNavigation sx={{ position: 'absolute', bottom: 0, left: 0, right: 56}} onChange={handleTabChange} value={tab}>

          <BottomNavigationAction label="Dashboard" icon={<DashboardIcon />} value={0} />
          { showCameraTab && 
            <BottomNavigationAction label="Cameras" icon={<VideocamIcon />} value={1} />
          }
          <BottomNavigationAction label="Favorites" icon={<FavoriteIcon />} value={2} />

        
      </BottomNavigation>

      <IconButton 
        sx={{ position: 'absolute', bottom: 8, right: 8}}
          aria-owns={menuOpen ? 'menu-appbar' : null}
          aria-haspopup="true"
          onClick={handleMenuClick}
        >
          <MoreVertIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={menuOpen}
        onClose={handleMenuClose}
        TransitionProps={{onEntered: handleMenuEnter, onExited: handleMenuExit}}
        //onEntered={handleMenuEnter} UPDATE ERROR: Warning: Failed prop type: The prop `onEntered` of `ForwardRef(Menu)` is deprecated. Use the `TransitionProps` prop instead.
        //onExited={handleMenuExit} UPDATE ERROR: Failed prop type: The prop `onExited` of `ForwardRef(Menu)` is deprecated. Use the `TransitionProps` prop instead.
      > 

        <MenuItem id="changeOrder" onClick={thisHandleMenuItemClick} selected={changingOrder ? true : false}>Change order</MenuItem>
        <MenuItem id="cameras" onClick={thisHandleMenuItemClick}>Cameras</MenuItem>
        <MenuItem sx={{
    '&:hover': {
      backgroundColor: 'error.light',
    },
  }} id="logout" onClick={thisHandleMenuItemClick} divider>Logout</MenuItem>
        <Collapse in={menuLoaded} appear >
          <MenuItem disabled dense id="version" onClick={thisHandleMenuItemClick} style={{float: 'right'}}><Typography variant="caption" color="textSecondary">{VERSION}</Typography></MenuItem>
        </Collapse>
      </Menu>
    </Paper>
  );
};
