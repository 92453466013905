import React, {useState, useRef} from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import {useOwnState} from './DefaultDevices';
import Widget from '../Widget';
import {domoticzCommand} from '../DomoticzConnection';
import {ICON_SIZE} from '../Constants';

export default function Thermostat({device}) {
  const [updating, setUpdating] = useState(false);
  const [setpoint, setSetpoint] = useOwnState(Number(device.SetPoint));
  const delay = useRef();
  
  function handleSetPoint(newSetPoint) {
    setUpdating(true);
    setSetpoint(newSetPoint);
    if(delay.current) {
      clearInterval(delay.current);
    }
    delay.current = setTimeout(() => {
      const query = {type: 'command', param: 'setsetpoint', idx: device.idx, setpoint: newSetPoint};
      setUpdating(false);
      setSetpoint(false);
      domoticzCommand(query);
    }, 2500);
  }
  
  const temp = setpoint.toString().split('.');
  const color = updating ? "secondary": "primary";

  return (
    <Widget center padding={0}>
      <IconButton
        onClick={
          () => handleSetPoint(
            setpoint % 0.5 === 0 ? setpoint-0.5 : (-(setpoint % 0.5)+setpoint)
          )
        }
        size="large"><ExpandMoreIcon /></IconButton>
      <Tooltip title={device.Name + ': ' + device.SetPoint.toString()+'°C'} enterTouchDelay={0}>
        <div style={{width: '4em', display: 'flex', justifyContent: 'center'}}>
          {Boolean(setpoint) &&
          <React.Fragment>
            <span style={{textAlign: "center", display: 'flex'}}>
              <Typography component="div" color={color} style={{fontSize: ICON_SIZE-(ICON_SIZE/7)+'px', lineHeight: ICON_SIZE-2+'px'}}>{temp[0]}</Typography>
              <Typography component="div" color={color} >{temp[1]}</Typography>
            </span>
          </React.Fragment>
          }
          {!Boolean(setpoint) &&
          <CircularProgress color="secondary" size={34} />
          }
        </div>
      </Tooltip>
      <IconButton
        onClick={
          () => handleSetPoint(
            setpoint % 0.5 === 0 ? setpoint+0.5 : (-(setpoint % 0.5)+setpoint+0.5)
          )
        }
        size="large"><ExpandLessIcon /></IconButton>
    </Widget>
  );
}