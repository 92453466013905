import React from 'react';
import Switch from '@mui/material/Switch';
import {useOwnState, isChecked, DeviceWithLabel} from './DefaultDevices';
import {domoticzCommand} from '../DomoticzConnection';

export default function SwitchOnOff({device}) {
  
  const [checked, setChecked] = useOwnState(isChecked(device.Data));

  function handleChange(event) {
    const data = event.target.checked ? 'On' : 'Off';
    setChecked(event.target.checked);
    const query = {type: 'command', param: 'switchlight', idx: device.idx, switchcmd: data, level: 0};
    domoticzCommand(query);
  }
  
  return(
    <DeviceWithLabel
      tooltip={device.LastUpdate}
      Device={Switch}
      deviceProps={{edge: "start", onChange: handleChange, inputProps: {disabled: device.Protected} }}
      checked={checked}
    >{device.Name}</DeviceWithLabel>
  );
}