import React, {useState} from 'react';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import SecurityIcon from '@mui/icons-material/Security';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {deviceSpacingStyle} from './DefaultDevices';
import Widget from '../Widget';
import {domoticzCommand} from '../DomoticzConnection';
import {ICON_SIZE} from '../Constants';

var level;

export default function SecurityPanel({device}) {
  const useStyles = makeStyles(theme => ({
    root: {
      fontSize: ICON_SIZE + 'px !important', 
      ...deviceSpacingStyle,
    },
  }));
  
  const [openKeypad, setOpenKeypad] = useState(false);
  const [error, setError] = useState(false);
  var seccode = '';
  const securityLevels = ["Normal", "Arm Home", "Arm Away"];
  const securityLevelsDisplayNames = ["Disarmed", "Arm Home", "Arm Away"];

  function handleLevelClick(e,newLevel) {
    setOpenKeypad(true);
    level = newLevel;
  }
  
  function handleKeypadClick(e, key) {
    seccode += key.toString();

    if(seccode.length === 4) {
      const md5 = require('md5');
      const query = {type: 'command', param: 'setsecstatus', secstatus: level, seccode: md5(seccode)};
      seccode = '';
      domoticzCommand(query, checkResponseForError);
    }
  }
  
  function checkResponseForError(data) {
    if (data && data.status !== 'OK') {
      setError(true);
    } else {
      setOpenKeypad(false);
      setError(false);
    }
  }
  
  function closeError() { 
    setError(false);
  }

  const classes = useStyles();
  return(
    <React.Fragment>
      <Widget>
        <Tooltip title={device.Name} enterTouchDelay={0}>
          <SecurityIcon color="action" classes={{root: classes.root}} />
        </Tooltip>
        { securityLevels.map((levelName,i) =>
          <Button  
            key={i}
            onClick={(e) => handleLevelClick(e,i)}
            color={device.Data === levelName ? "secondary" : "primary"}
            //variant={device.Data === levelName ? "contained" : "outlined"}

            fullWidth
          >
            {securityLevelsDisplayNames[i]}
          </Button>
        )}
      </Widget>
      <Dialog
        open={openKeypad}
        onClose={() => setOpenKeypad(false)}
        maxWidth={'xs'}
        aria-labelledby="keypad-dialog-title"
        aria-describedby="keypad-dialog-description"
      >
        <DialogTitle id="keypad-dialog-title" align="center">{"Security Panel"}</DialogTitle>
        <DialogContent style={{	display: 'flex', flexWrap: 'wrap', justifyContent: 'center', textAlign: 'center'}}>
          { [1,2,3,4,5,6,7,8,9,0].map((key) => 
            <div key={key} style={{	padding: '12px', flexBasis: '33%'}}>
              <Fab 
                onClick={(e) => handleKeypadClick(e,key)}
                sx={{
                  color: "text.secondary",
                  backgroundColor: "background.default",
                  '&:hover': {
                    color: "text.primary",
                    backgroundColor: "secondary.main",
                  },
                }}
              >
                {key}
              </Fab>
            </div>
          )}
        </DialogContent>
        <Dialog
          open={openKeypad && error }
          onClose={closeError}
          maxWidth={'xs'}
        ><DialogContent onClick={closeError}>
          <DialogContentText color="error" >
            Wrong security code, try again
          </DialogContentText>
         </DialogContent>
        </Dialog>
      </Dialog>
    </React.Fragment>
  );
}