import { createStore } from 'redux';

export const addDevice = device => ({
  type: 'ADD_DEVICE',
  device,
})


export const updateDevice = device => ({
  type: 'UPDATE_DEVICE',
  device,
})

const devicesReducer = (devices = [], action) => {
  switch (action.type) {
    case 'ADD_DEVICE':
      return [
        ...devices,
        action.device
      ]
    case 'UPDATE_DEVICE':
      return devices.map(device =>
        (device.idx === action.device.idx)
          ? action.device
          : device
      )
    default:
      return devices
  }
};

export const store = createStore(devicesReducer);