import React from 'react'
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import {DeviceIconWithLabel} from './DefaultDevices';

export default function MotionSensor({device}) {    
  return( 
    <DeviceIconWithLabel 
      Icon={DirectionsRunIcon}
      iconColor={device.Data === "Off" ? "disabled" : "secondary"}
      tooltip={device.LastUpdate}
     >{device.Name}</DeviceIconWithLabel>
  );
}