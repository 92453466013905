import "core-js/stable";
import "regenerator-runtime/runtime";
import 'typeface-roboto';
import { createRoot } from 'react-dom/client';

import registerServiceWorker from './registerServiceWorker';
import App from './App';

const container = document.getElementById('akerboom.app');
const root = createRoot(container);
root.render(<App />);
registerServiceWorker();