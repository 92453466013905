import React from 'react'
import PowerIcon from '@mui/icons-material/Power';
import Hidden from '@mui/material/Hidden';
import {DeviceIconWithLabel, useHighlighting} from './DefaultDevices';

export default function MeterUsage ({device, gridItem}) {  
  const highlighting = useHighlighting(device);

  return (
    <DeviceIconWithLabel 
      Icon={PowerIcon} 
      iconColor={device.Data === '0 Watt' ? "disabled" : (highlighting ? 'primary' : "action") }
      tooltip={device.Name}
    >
      {device.Data} 
      <Hidden smDown={gridItem}> ({device.Name})</Hidden>
    </DeviceIconWithLabel>
  );
}