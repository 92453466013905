import React from 'react';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Slider } from '@mui/material'
import Tooltip from '@mui/material/Tooltip';

import {useOwnState, isChecked} from './DefaultDevices';

import Widget from '../Widget';
import {domoticzCommand} from '../DomoticzConnection';

export default function SwitchDimmer({device}) {
  
  const [checked, setChecked] = useOwnState(isChecked(device.Status));
  const [level, setLevel] = useOwnState(device.LevelInt);
  
  function handleSwitchChange(e, value) {
    const status = value ? 'On' : 'Off';
    setChecked(value);
    const query = {type: 'command', param: 'switchlight', idx: device.idx, switchcmd: status, level: 0};
    domoticzCommand(query);
  }
  
  function handleSliderChanged(newLevel = level) {
    const query = {type: 'command', param: 'switchlight', idx: device.idx, switchcmd: 'Set Level', level: Math.round(newLevel)};
    domoticzCommand(query);
  }

  function onChange(e, newLevel) {
    setLevel(newLevel);
  }
  
  function onChangeCommitted(e, newLevel) {
    setLevel(newLevel);
    handleSliderChanged(newLevel);
  }

  return(
    <Widget>
      <Tooltip title={device.LastUpdate} enterTouchDelay={0}>
        <FormControlLabel
          control={<Switch edge="start" checked={checked} onChange={handleSwitchChange} />}
          label={device.Name}
          style={{minWidth: '50%'}}
          disabled={device.HaveTimeout}
        />
      </Tooltip>
      <Slider 
        onChangeCommitted={onChangeCommitted}
        onChange={onChange}
        value={Number(level)}
        style={{marginLeft: 16, marginRight: 8}}
        disabled={device.HaveTimeout}
      />
    </Widget>
  );
}
