import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@mui/styles';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Checkbox from '@mui/material/Checkbox';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import useMediaQuery from '@mui/material/useMediaQuery';
import DomoticzDevice from './DomoticzDevice';

const useStyles = makeStyles(theme => ({
  widgetContainer: {width:'100%', paddingBottom: theme.spacing(1)},
  disabledWidget: {
    filter: 'invert(17%)'
  },
}));

function FavCheckbox({device, children, checkedFavHandler, selectFavHandler}) {
 
  const [checked,setChecked] = useState(() => checkedFavHandler(device));

  function onFavChange (e, myChecked) {
    selectFavHandler(device, myChecked);
    setChecked(myChecked);
  };
  return(
    <label style={{cursor: 'pointer'}} htmlFor={device.idx}>
      <div style={{position: 'relative', marginRight: 42}}>
        <div style={{position: 'absolute', top: 8, right: -38}}>
          <Checkbox
            style={{zIndex: 1}}
            icon={<FavoriteBorderIcon style={{ fontSize: 16 }} />} 
            checkedIcon={<FavoriteIcon style={{ fontSize: 16 }}/>} 
            id={device.idx}
            onChange={onFavChange}
            checked={checked}
          />
        </div>
        {children}
      </div>
    </label>
  );
};

export default function FavDialog({allDevices, selectFavHandler, checkedFavHandler, open, onClose}) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog 
      fullScreen={fullScreen}
      maxWidth="xs"
      fullWidth
      aria-labelledby="fav-dialog-title"
      open={open}
      onClose={onClose}
      PaperProps={{style: {backgroundColor: theme.palette.background.default}}}
    >
      <DialogTitle id="fav-dialog-title">Select devices to display</DialogTitle>
      <DialogContent style={{zIndex: 1}}>
        <div style={{maxHeight: '50vh'}}>
          {allDevices.map(device => (
          <div className={classes.widgetContainer} key={`fav-${device.idx}`}>
            <FavCheckbox
              selectFavHandler={selectFavHandler}
              checkedFavHandler={checkedFavHandler}
              device={device}
            >
              <div style={{pointerEvents: 'none'}} className={classes.disabledWidget} >
                <DomoticzDevice
                  gridItem={false}
                  device={device} 
                />
              </div>
            </FavCheckbox>
          </div>
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
}

FavDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  selectFavHandler: PropTypes.func.isRequired,
  checkedFavHandler: PropTypes.func.isRequired,
  allDevices: PropTypes.array.isRequired,
};