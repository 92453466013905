import React from 'react'
import FileDownloadIcon from '@mui/icons-material/GetApp';
import {DeviceIconWithLabel, useHighlighting} from './DefaultDevices';
import {numberWithDots} from '../util/HelperFunctions';

export default function Download({device}) {
  const highlighting = useHighlighting(device);
  
  return (
    <DeviceIconWithLabel 
      Icon={FileDownloadIcon}
      iconColor={highlighting ? "primary" : "action"}
      tooltip={device.Name}
    >
      {numberWithDots(device.Data)}
    </DeviceIconWithLabel>
  );
}