import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';


export const numberWithDots = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

// export const compose = (...funcs) =>
//   funcs.reduce((a, b) => (...args) => a(b(...args)), arg => arg);

export function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
}

export function usingDarkTheme() {
  return (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);
}