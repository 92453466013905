import React from 'react';
import MovieFilerIcon from '@mui/icons-material/MovieFilter';
import {domoticzCommand} from '../DomoticzConnection';
import {DeviceIconWithLabel} from './DefaultDevices';

export default function SwitchScene({device}) {
  const handleClick = () => event => {
    const query = {
      type: 'command', 
      param: 'switchscene', 
      idx: device.idx, 
      switchcmd: 'On'
    };
    domoticzCommand(query);
  };
  
  return (
    <DeviceIconWithLabel 
      onClick={handleClick()}
      Icon={MovieFilerIcon}
      tooltip={device.LastUpdate}
    >
      {device.Name}
    </DeviceIconWithLabel>
  );
}