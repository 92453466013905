import React from 'react'
import Typography from '@mui/material/Typography';
import {DeviceWithLabel, deviceSpacingStyle} from './DefaultDevices';
import {ICON_SIZE} from '../Constants';
import {useWidth} from '../util/HelperFunctions';

export default function Temperature({device}) {
  const width = useWidth();
  const trend = device.trend === 2 ? " [⬆]" :" [⬇]"
  return <DeviceWithLabel
          tooltip={device.HaveTimeout ? device.LastUpdate : device.Name + ': ' + device.Temp.toString()+'°C' + trend + ' [' + device.LastUpdate + ']'} 
          Device={Typography}
          deviceProps={{style: {fontSize: ICON_SIZE-(ICON_SIZE/7)+'px', textAlign: 'center', ...deviceSpacingStyle}, children: Math.round(device.Temp), color: device.HaveTimeout ? "error" : "primary"}}
         >{width !== 'xs' ? device.Name + ' ' + trend : device.Name}</DeviceWithLabel>
}