import React from 'react';
import Switch from '@mui/material/Switch';
import {useOwnState, isChecked, DeviceWithLabel} from './DefaultDevices';
import {domoticzCommand} from '../DomoticzConnection';

export default function SwitchGroup({device}) {
  const [checked, setChecked] = useOwnState(isChecked(device.Data));
       
  function handleChange(event) {
    const data = event.target.checked ? 'On' : 'Off';
    setChecked(event.target.checked);
    const query = {
      type: 'command', 
      param: 'switchscene', 
      idx: device.idx, 
      switchcmd: data
    };
    domoticzCommand(query);
  }

  return(
    <DeviceWithLabel
      tooltip={device.LastUpdate}
      Device={Switch}
      deviceProps={{edge: "start", onChange: handleChange}}
      checked={checked}
    >{device.Name}</DeviceWithLabel>
  );
}