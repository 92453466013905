import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {store, addDevice, updateDevice} from './devicesStore';

function objectToQuery(data) {
  const keyvals = [];
  for (const key in data) {
    if ({}.hasOwnProperty.call(data, key)) {
      keyvals.push(key + '=' + encodeURIComponent(data[key]));
    }
  }
  return keyvals.join('&');
}

export default function useDomoticzConnection(auth, sunSetRise) {
  const [serverAuth, setServerAuth] = useState(auth);
  const [loggedIn, setLoggedIn] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [offline, setOffline] = useState(true);
  const [gotAllDevices, setGotAllDevices] = useState(false);
  const lastUpdate = useRef();
  const aliveTimer = useRef();

  function addOrUpdateDevices(updatedDevices) {
    const devices = store.getState();
    for (const newDevice of updatedDevices) {
      const exists = devices.some((device) => device["idx"] === newDevice["idx"]);
      if(exists) {
        store.dispatch(updateDevice(newDevice));
      } else {
        if(newDevice.Used !== 0) {
          store.dispatch(addDevice(newDevice));
        }
      }
    }
  }
  
  useEffect(() => {
    let wsClient;
    function createWebSocket() {
      const TIMEOUT = 11;
      wsClient = new WebSocket('wss://'+ window.location.host +'/json', 'domoticz');
      wsClient.onopen = () => {
        setOffline(false);
        wsClient.send(JSON.stringify(
          {event: "request", 
          requestid: 0, 
          query: objectToQuery(
            {type: "command", 
            param: "getdevices", 
              filter: "all", 
              used: "true", 
//                     order: "[Order]", 
              lastupdate: lastUpdate.current, 
              plan: 0}
          )}
        ));
      };
      wsClient.onmessage = (message) => {
        const response = JSON.parse(message.data);
        if(response.data) {
          const data = JSON.parse(response.data);
          if(data.ActTime && response.requestid === -1) lastUpdate.current = data.ActTime;
          if(data.result) {
            addOrUpdateDevices(data.result);
          }
          setOffline(false);
        } else if(response.event === "date_time") {
          clearTimeout(aliveTimer.current);
          aliveTimer.current = setTimeout(() => {
            setOffline(true);
          }, TIMEOUT*1000);
        }
      }
      wsClient.onclose = (event) => {
        if(!event.wasClean) createWebSocket();
        setOffline(true);
      };
    }
    if(gotAllDevices) {
      createWebSocket();
      return(() => {
        clearTimeout(aliveTimer.current);
        if(wsClient) wsClient.close();
        lastUpdate.current = null;
      });
    }
  },[gotAllDevices]);
  
  useEffect(() => {
    function getAllDevices() {
      const queryData = {type: 'command', param: 'getdevices', filter: 'all', /* order: 'Name',*/ used: 'true'};
      axios.get('./json.htm?' + objectToQuery(queryData)/*,{timeout: TIMEOUT}*/).then(response => {
        if(response.data.ActTime) {
          lastUpdate.current = response.data.ActTime;
        }
        sunSetRise.current = [response.data.Sunset, response.data.Sunrise];
        addOrUpdateDevices(response.data.result);
        setGotAllDevices(true);
      }).catch(error => {
        setLoginError(true);
        console.error('Unable to reach Domoticz:', error);
      }); 
    };
    if(loggedIn) {
      getAllDevices();
      return(() => {
        lastUpdate.current = null;
        setLoginError(false);
        setGotAllDevices(false);
      });
    }
  },[loggedIn,sunSetRise]);
  
  useEffect(() => {  
    if(!serverAuth || serverAuth[0] == null || serverAuth[1] == null) {
      setLoggedIn(false);
    } else {
      const [username, password] = serverAuth;

      const queryData = {type: 'command', param: 'logincheck', username, password, rememberme: 'true'};
      axios.get('./json.htm?' + objectToQuery(queryData)).then(response => {
        if(response.data.status === 'OK') {
          setLoggedIn(true);
          setLoginError(false);
        } else {
          setLoggedIn(false);
          setLoginError(true);
        }   
      }).catch(error => {
          setLoggedIn(false);
          setLoginError(true);
      });
      return(() => {
        domoticzCommand({type: 'command', param: 'dologout'});
        setLoggedIn(false);
        setOffline(true);
        setLoginError(false);
      });
    }
  },[serverAuth]);

  return [{loggedIn, loginError, offline}, setServerAuth, gotAllDevices];
}

function defaultCallback(data) {
  if (data && data.status !== 'OK') {
    console.log('Domoticz JSON query command failed', data);
  }
}

export function domoticzCommand(queryData, callback=defaultCallback) {
  axios.get('./json.htm?' + objectToQuery(queryData)/*,{timeout: TIMEOUT}*/).then(response => {
    if(response.data && callback) {
      callback(response.data);
    }
  }).catch(error => {
    console.error('Unable to reach Domoticz:', error);
    if(callback) {
      callback({});
    }
  });
}