import React from 'react';
import { makeStyles } from '@mui/styles';
import Hidden from '@mui/material/Hidden';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import yellow from '@mui/material/colors/yellow';

import {DeviceIconWithLabel} from './DefaultDevices';

const useStyles = makeStyles({
  Sunny: {
    animation: '$sunny 2s infinite',
    color: yellow[600],
  },
  '@keyframes sunny': {
    '0%': {
      transform: 'scale(1) rotate(0deg)',
    },
    '50%': {    
      transform: 'scale(1.2) rotate(15deg)',
    },
    '100%': {
      transform: 'scale(1) rotate(0deg)',
    }
  }
});

export default function Lux(props) {
  const classes = useStyles();
  const {device, gridItem} = props;
  const lux = parseInt(device.Data.split(" ")[0],10);
  return (
    <DeviceIconWithLabel 
      Icon={WbSunnyIcon} 
      iconClasses={lux > 500 ? {root: classes.Sunny} : {}}
      iconColor={lux < 20 ? "disabled" : "action"}
      tooltip={device.Name + ' [' + device.LastUpdate + ']'}
    >{device.Data}<Hidden smDown={gridItem}> ({device.Name})</Hidden></DeviceIconWithLabel>
  );
}
