import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import PageVisibility from 'react-page-visibility';

import CameraProgressIndicator from './CameraSnapshotProgress';
import {CAMERA_OFFLINE_RETRY} from './Constants';

const styles = theme => ({

});


class CameraSnapshot extends React.PureComponent {  
  
  constructor(props) {
    super(props);
    this.state = {camSrc: false, lastUpdate: new Date(), online: false, fullscreen: false};
    this.ProgressIndicator = React.createRef();
//     this.loadNext = this.loadNext.bind(this); // https://reactjs.org/docs/refs-and-the-dom.html#caveats
//     this.render = this.render.bind(this);
    this.loading = false;
    this.active = false;
    this.camInterval = parseInt(props.camera.camInterval, 10);
    this.camIntervalDelta = 0;
  }
 
  componentDidMount() {
    
    /* workaround: https://github.com/pgilad/react-page-visibility/issues/9 */
    document.addEventListener('resume', (event) => {
      this.handleVisibilityChange(true);
    });
    document.addEventListener('freeze', (event) => {
      this.handleVisibilityChange(false);
    });
    this.loadNext();
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
    this.active = false;
  }

  componentDidUpdate(prevProps) {
    if(prevProps.tabVisible !== this.props.tabVisible) {
      this.handleVisibilityChange(this.props.tabVisible);
    }
  }
  
  loadNext() {
    if(!this.active) {
      this.active = true;
      this.forceUpdate();
    }
    
    function preloadImage (path) {
      return new Promise(function (resolve, reject) {
        let image = new Image();
        image.onload = resolve;
        image.onerror = reject;
        image.src = path;
      });
    };
    
    if(!this.loading) {
      this.loading = true;
      const startLoadingTime = new Date().getTime();
      const camSrc = this.props.camera.camSrc + "&nocache=" + startLoadingTime;
      preloadImage(camSrc)
        .then(() => {
          if(this.props.tabVisible && this.active) {
            this.setState({camSrc, lastUpdate: new Date(), online: true});
          }
        })
        .catch(() => {
          if(this.props.tabVisible && this.active){
            this.timer = setTimeout(() => this.loadNext(), CAMERA_OFFLINE_RETRY);
            this.setState({online: false});
          }
        })
        .finally(()=>{
          this.loading = false; 
          if(this.props.tabVisible && this.active) {
            this.camIntervalDelta = new Date().getTime() - startLoadingTime;
            if(this.ProgressIndicator.current) {
              this.ProgressIndicator.current.progress.reset();
            }
          }
        })
      ;
    }
  } 
  
  stopLoading() {
    clearTimeout(this.timer);
    this.active = false;
    this.setState({online: false});
  }
  
  handleVisibilityChange = (isVisible)=> {
      if(isVisible && this.props.tabVisible) {
        this.loadNext();
      } else if (!isVisible) {
        this.stopLoading();
      }
  }

  imgSetTimeout= () => {
    this.timer = setTimeout(() => this.loadNext(),this.props.camera.camInterval);
  }
  
  imgFullscreen = () => {
    
    if('orientation' in window.screen && document.documentElement.webkitRequestFullscreen) {
      if(this.state.fullscreen) {
        document.webkitExitFullscreen();
        window.screen.orientation.unlock();
      } else {
        document.documentElement.webkitRequestFullscreen();
        window.screen.orientation.lock("landscape").catch(function(error) {
          console.log('Changing device orientation not supported!');
        });
      }
    }

    this.setState({fullscreen: !this.state.fullscreen});
  }
  
  resetProgress = () => {
    this.stopLoading(); 
    this.loadNext(); 
    this.ProgressIndicator.current.progress.complete();
  }
  
  render() {
    
    return (
      <PageVisibility onChange={this.handleVisibilityChange}>
        <Grid container spacing={0}>
          <Grid item xs={12} style={{display: 'grid'}}>
            { !this.state.camSrc && 
            <div style={{width: '100%', paddingTop: 'calc(56.25% - 44px)', backgroundColor: this.props.theme.palette.background.default}}>
              <CircularProgress
                style={{position: 'relative', top: 'calc(-50% + 24px)', left: 'calc(50% - 20px)'}}
              /> 
            </div>
            }
            { this.state.camSrc &&
            <img 
              src={this.state.camSrc}
              alt={this.props.camera.camName}
              onLoad={this.imgSetTimeout}
              onClick={this.imgFullscreen}
              style={this.state.online ? {} : {filter: 'blur(2px)'}}
              width="100%"
            />
            } 
            {this.props.tabVisible && this.active && 
            <div style={{position: 'relative', bottom: '0px'}}>
              <div style={{position: 'absolute', bottom: '4px', left: 0, right: 0, margin: 'auto', height: '48px', width: '40%', backgroundColor: 'rgba(0,0,0,0.48)', borderRadius: '24px'}}>
                  <span style={{padding: '12px', float:'left'}}>
                  <Typography variant="subtitle1" style={{color: 'rgba(255,255,255,0.66)'}}>{this.props.camera.camName}</Typography>
                  </span>
                  <span style={{float: 'right'}}>
                  <IconButton onClick={this.resetProgress} size="large">
                    <CameraProgressIndicator 
                      ref={this.ProgressIndicator}
                      camInterval={this.camInterval}
                      camIntervalDelta={this.camIntervalDelta}
                      online={this.state.online}
                    />
                  </IconButton>
                  </span>
                </div>
              </div>
              }
          </Grid>
          <Dialog open={this.state.fullscreen} onClose={this.imgFullscreen} fullScreen>
            <img 
              src={this.state.camSrc}
              alt={this.props.camera.camName}
              onClick={this.imgFullscreen}
              width="100%"
            />
          </Dialog>
        </Grid>
      </PageVisibility>
    );
  }
}

CameraSnapshot.propTypes = {
  camera: PropTypes.object.isRequired,
  tabVisible: PropTypes.bool.isRequired,
};

//export default withWidth()(withTheme()(CameraSnapshot));
export default withStyles(styles, { withTheme: true })(CameraSnapshot);
