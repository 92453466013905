import React from 'react';
// import withWidth from '@mui/material/withWidth';
import Avatar from '@mui/material/Avatar';

import {DeviceWithLabel, DefaultDeviceIconOnly, deviceSpacingStyle} from './DefaultDevices';
import {ICON_SIZE} from '../Constants';
import {useWidth} from '../util/HelperFunctions';

export default function Phone({device, gridItem}) {

  let avatarSrc = device.Description || "https://lh3.googleusercontent.com/-XdUIqdMkCWA/AAAAAAAAAAI/AAAAAAAAAAA/4252rscbv5M/";
  const avatarSrcSetSizes = [1,2,3,4,5,6,7];
  const avatarSrcSet = avatarSrcSetSizes.map(size => avatarSrc+'?sz='+size*ICON_SIZE+' '+size+'x');
  avatarSrc += '?sz='+ICON_SIZE;
  
  function isActive(data) {
    return (data === 'Off' ? false : true);
  }
  
//   async getProfilePicture(email) {
//     var result = await this.checkGoogle(email);
//      console.log(result);
//   }
  
//   checkGoogle = function(email) {
//     return fetch("https://picasaweb.google.com/data/entry/api/user/" + email + "?alt=json")
//     .then(response => response.json())
//     .then(function(body) {
//       return body.entry.gphoto$thumbnail.$t;
//     })
//   }
  

//     const {device, gridItem, width} = this.props;
  const width = useWidth();
  const inactive = !isActive(device.Data);
  const avatarImgProps = inactive ? {style:{filter: 'grayscale(100%)', opacity: 0.54}} : {};
  const avatarProps = {alt: device.Name, imgProps:avatarImgProps, src: avatarSrc, srcSet:avatarSrcSet.join()};

  return(
    <React.Fragment>
      { (width !== 'xs' || !gridItem) &&
      <DeviceWithLabel 
        Device={Avatar}
        deviceProps={{style: { ...deviceSpacingStyle}, ...avatarProps }}
        tooltip={device.LastUpdate}
      >{device.Name}</DeviceWithLabel>
      }
      { width === 'xs' && gridItem &&
      <DefaultDeviceIconOnly 
        Icon={Avatar}
        iconProps={avatarProps}
        tooltip={<React.Fragment>{device.Name}<br />{device.LastUpdate}</React.Fragment>}
       />
      }
    </React.Fragment>
  );

}