import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import {CAMERA_OFFLINE_RETRY, CAMERA_PROGRESS_REFRESH_INTERVAL} from './Constants';

class CameraSnapshotProgress extends React.Component {
 
  progressTimer;
  state  = {completed: 100};
  
  progress = {
    calcStep: () => {
      const camInterval = this.props.camInterval + this.props.camIntervalDelta;
      const interval = this.props.online ? camInterval : CAMERA_OFFLINE_RETRY;
      return 100/(interval/CAMERA_PROGRESS_REFRESH_INTERVAL);
    },
    isStreaming: () => {
      return (this.progress.calcStep() > CAMERA_PROGRESS_REFRESH_INTERVAL/10);
    },
    calc: () => {
      let progress = this.state.completed + this.progress.calcStep();
      if (this.progress.isStreaming() || progress > 100) progress = 100;
      this.setState({completed: progress});
    },   
    reset: () => {
      if(!this.progress.isStreaming()) this.setState({completed: 0});
    },
    complete: () => {
      this.setState({completed: 100});
    },
  };

  componentDidMount() {
    this.progressTimer = setInterval(this.progress.calc, CAMERA_PROGRESS_REFRESH_INTERVAL);
  };

  componentWillUnmount() {
    clearInterval(this.progressTimer);
  };
  
  shouldComponentUpdate(nextProps, nextState) {
    if(nextState.completed !== this.state.completed || nextProps.online !== this.props.online) {
      return true;
    }
    return false;
  }

  render() {
    return (
      <CircularProgress 
        variant="determinate" 
        size={24} 
        thickness={7} 
        value={this.state.completed} 
        style={{ color: (this.props.online) ? 'rgba(76, 175, 80, 0.54)' : 'rgba(221, 44, 0, 0.54)' }}
      />
    );
  };
};

export default CameraSnapshotProgress;
