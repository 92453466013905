import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem';

import DeleteIcon from '@mui/icons-material/Delete';
import FileUploadIcon from '@mui/icons-material/Publish';
import TabIcon from '@mui/icons-material/Tab';
import SaveIcon from '@mui/icons-material/Save';

const useStyles = makeStyles(theme => ({
  button: {
    marginBottom: theme.spacing(1),
  },
  buttonIcon: {
    marginLeft: 'auto',
  },
  formControl: {
    width: '100%',
  },
  deleteIcon: {
    left: '8px',
    bottom: '8px',
    position: 'absolute',
  }
}));

export default function CamDialog({onSubmit,onCancel,open,cameras,onHideTab,showCameraTab=true}) {
  let myCameras = [...cameras];
  const options = Object.keys(cameras).map(function(key) {
    return [cameras[key].camName];
  });
  // options.push("New camera");
  const [error,setError] = useState(false);

  const [cam,setCam] = useState("");
  const [camName, setCamName] = useState("");
  const [camSrc, setCamSrc] = useState("");
  const [camInterval, setCamInterval] = useState("");

  function handleChange(event) {
    const value = event.target.value;
    switch(event.target.id) {
      case "camName": 
        setCamName(value);
        break;
      case "camSrc":
        setCamSrc(value);
        break;
      case "camInterval":
        setCamInterval(value);
        break;
      default:
    }
  };

  function handleSelectChange(event) {
    let value = event.target.value;
    setCam(value);
    if(value !== "") {
      let camera = cameras[value];
      setCamName(camera.camName);
      setCamSrc(camera.camSrc);
      setCamInterval(camera.camInterval);
    }
  };
  
  function handleSubmit() {
    if(camName && camSrc && camInterval) {
      if(cam === "") {
        myCameras.push({camName, camSrc, camInterval});
      } else {
        myCameras[cam] = {camName, camSrc, camInterval};
      }
      onSubmit(myCameras);
    } else {
      setError(true);
    } 
  };

  function deleteCam() {
    myCameras.splice(cam,1);
    setCam("");
    onSubmit(myCameras);
  }

  const classes = useStyles();
  return (
    <Dialog 
      maxWidth="xs"
      aria-labelledby="cam-dialog-title"
      open={open}
      onClose={onCancel}
    >
      <DialogTitle id="cam-dialog-title">Manage security cameras</DialogTitle>
      <DialogContent>
        <Button fullWidth component="a" variant="outlined" color="primary" onClick={onHideTab} className={classes.button}>
          {showCameraTab ? "Hide": "Show"} the camera tab<TabIcon className={classes.buttonIcon} />
        </Button>
        <br />
        <Button fullWidth component="a" variant="outlined" color="primary" download="cameras.backup" href={URL.createObjectURL(new Blob([JSON.stringify(cameras)])) } className={classes.button}>
          Save backup <SaveIcon className={classes.buttonIcon} />
        </Button>
        <br />
        <input
          accept=".backup"
          style={{display: 'none'}}
          id="button-restore"
          type="file"
          onChange={
            (e) => {
              let reader = new FileReader();
              let file = e.target.files[0];
              reader.onloadend = () => {
                onSubmit(JSON.parse(reader.result));
              }
              reader.readAsText(file);
            }
          }
        />
        <label htmlFor="button-restore">
          <Button fullWidth component="span" variant="outlined" color="primary" className={classes.button}>
            Restore backup<FileUploadIcon className={classes.buttonIcon} />
          </Button>
        </label>
        <br />
        <Divider />
        <br />
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="demo-simple-select-outlined-label">
            Select Camera
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={cam}
            MenuProps={{
              id: 'cam',
            }}
            onChange={handleSelectChange}
            //labelWidth={106}
          >
            <MenuItem value="">
              <em>New Camera</em>
            </MenuItem>
            {options.map((option, index) => (
              <MenuItem key={index} value={index}>{option}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <br />
        <TextField
          required
          fullWidth
          id="camName"
          label="Name"
          margin="dense"
          onChange={handleChange}
          error={error}
          value={camName}
        />
        <TextField
          required
          fullWidth
          id="camSrc"
          label="Snapshot URL"
          margin="dense"
          onChange={handleChange}
          error={error}
          value={camSrc}
        />
        <TextField
          required
          fullWidth
          id="camInterval"
          label="Refresh interval (ms)"
          margin="dense"
          type="number"
          onChange={handleChange}
          error={error}
          value={camInterval}
        />
      </DialogContent>
      <DialogActions>
        { cam !== "" &&
        <IconButton
          aria-label="delete"
          className={classes.deleteIcon}
          onClick={() => window.confirm("Removing camera \""+options[cam]+"\", this can't be undone!") && deleteCam()}
          size="large">
          <DeleteIcon fontSize="small" color="secondary" />
        </IconButton>
        }
        <Button onClick={onCancel} color="primary" autoFocus>
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          {cam === "" ? "Add" : "Edit"} camera
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/*CamDialog.defaultProps = {
  showCameraTab: true,
};*/

CamDialog.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  cameras: PropTypes.array.isRequired,
  onHideTab: PropTypes.func.isRequired,
  showCameraTab: PropTypes.bool,
};

// export default withStyles(styles)(CamDialog);