import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import AirIcon from '@mui/icons-material/Air';
import {useOwnState, deviceSpacingStyle} from './DefaultDevices';
import Widget from '../Widget';
import {domoticzCommand} from '../DomoticzConnection';
import {ICON_SIZE} from '../Constants';

const useStyles = makeStyles({
  root: {
    fontSize: ICON_SIZE + 'px !important', 
    ...deviceSpacingStyle,
    
  },
  /*Low: {
    animation: '$circular-rotate 2.1s linear infinite',
  },
  Medium: {
    animation: '$circular-rotate 1.4s linear infinite',
  },
  High: {
    animation: '$circular-rotate 0.7s linear infinite',
  },
  '@keyframes circular-rotate': {
    '100%': {
      transform: 'rotate(360deg)',
    },
  },*/
});

export default function SwitchSelector({device}) {
  let levelNames;
  const [level, setLevel] = useOwnState(device.LevelInt);
  const classes = useStyles();

  try {
    levelNames = atob(device.LevelNames).split('|');
  } catch(error) {
    levelNames = device.LevelNames.split('|');
  }
  if(device.LevelOffHidden) {
    levelNames.shift();
  }

  function getMeterialIcon(icon) {
    switch(icon) {
      case "Fan":
        return <AirIcon classes={{root: classes.root}} />;
        //return <AirIcon color="action" classes={{root: classNames(classes.root, classes[levelNames[(device.LevelInt/10)-1]])}} />;
      default:
        return <div />;
    }
  }
  
  function handleSwitch(e,newLevel) {
    setLevel(newLevel);
    const query = {type: 'command', param: 'switchlight', idx: device.idx, switchcmd: 'Set Level', level: newLevel};
    domoticzCommand(query);
  }    
  
  return(
    <Widget>
      <Tooltip title={device.Name} enterTouchDelay={0}>
        { getMeterialIcon(device.Image) }
      </Tooltip>
      <ButtonGroup fullWidth>
        { levelNames.map((levelName,i) =>
        <Button
          onClick={(e) => handleSwitch(e, device.LevelOffHidden ? (i+1)*10 : i*10)}
          key={levelName}
          variant={(device.LevelOffHidden ? level === (i+1)*10 : level === i*10 ) ? "contained" : "outlined"}
        >
          {levelName}
        </Button>
      )}
      </ButtonGroup>
    </Widget>
  );
}