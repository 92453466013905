import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

class ServiceWorkerModel extends React.PureComponent {
  state = {
    msg: null,
  };
  componentDidMount() {
    // Handle global event.
    window.addEventListener("serviceWorkerMsg", (e) => {
      this.setState({
        msg: e.detail.msg,
      });
      this.props.onShow(true);
    });
  };

  handleClose = (e, autoClose = false) => {
    if(!autoClose) {
      this.props.onShow(false);
    }
  };

  render() {
    const { msg } = this.state;
    const { snackbarActive } = this.props;

    return (
      <Snackbar
        open={snackbarActive}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        onClose={this.handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{msg}</span>}
        action={
          <IconButton
            color="inherit"
            onClick={this.handleClose}
            style={{width: 40, height: 40}}
            size="large">
            <CloseIcon style={{fontSize: 20}} />
          </IconButton>
        }
      />
    );
  }
}

export default ServiceWorkerModel;