import React from 'react'
import FlashOnICons from '@mui/icons-material/FlashOn';
import yellow from '@mui/material/colors/yellow';
import {DeviceIconWithLabel, useHighlighting} from './DefaultDevices';

export default function Download({device}) {
  const highlighting = useHighlighting(device);
  
  return (
    <DeviceIconWithLabel 
      Icon={FlashOnICons}
      htmlColor={highlighting ? yellow[600] : undefined}
      tooltip={device.Name+": "+device.Usage}
    >
      {device.CounterToday}
    </DeviceIconWithLabel>
  );
}