// In production, we register a service worker to serve assets from local cache.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on the "N+1" visit to a page, since previously
// cached resources are updated in the background.

// To learn more about the benefits of this model, read https://goo.gl/KwvDNy.
// This link also includes instructions on opting out of this behavior.

export default function register() {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.toString());
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebookincubator/create-react-app/issues/2374
      return;
    }

    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
      registerValidSW(swUrl);
    });
  }
}

function registerValidSW(swUrl) {
  navigator.serviceWorker
    .register(swUrl)
    .then(registration => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // At this point, the old content will have been purged and
              // the fresh content will have been added to the cache.
              // It's the perfect time to display a "New content is
              // available; please refresh." message in your web app.
              const msg = 'New version is available; reloading...';
              // Append dispatch event
              const event = new CustomEvent("serviceWorkerMsg", {detail: {msg}});
              window.dispatchEvent(event);
              const waitingServiceWorker = registration.waiting
              if (waitingServiceWorker) {
                waitingServiceWorker.addEventListener("statechange", event => {
                  if (event.target.state === "activated") {
                    setTimeout(() => window.location.reload(true),1000);
                  }
                });
                waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
              }
            } else {
              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.
              const msg = 'App cached for maximum performance.';
              // Append dispatch event
              const event = new CustomEvent("serviceWorkerMsg", {detail: {msg}});
              window.dispatchEvent(event);
            }
          }
        };
      };
    })
    .catch(error => {
      const msg = 'Error during service worker registration.';
      console.error(msg, error);
      // Append dispatch event
      const event = new CustomEvent("serviceWorkerMsg", {detail: {msg}});
      window.dispatchEvent(event);
    });
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister();
    });
  }
}
