import React from 'react'
import WhatshotIcon from '@mui/icons-material/Whatshot';
import {DeviceIconWithLabel, useHighlighting} from './DefaultDevices';

export default function MeterGas({device}) { 
  const highlighting = useHighlighting(device);
  
  return (
    <DeviceIconWithLabel 
      Icon={WhatshotIcon}
      iconColor={highlighting ? 'secondary' : "action"}
      tooltip={device.Name}
    >
      {device.CounterToday}
    </DeviceIconWithLabel>
  );
}