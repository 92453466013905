import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import PropTypes from 'prop-types';
import Widget from '../Widget';
import {ICON_SIZE, WIDGET_HIGHLIGHT_DURATION} from '../Constants';
import { onlyText } from 'react-children-utilities';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

export const DeviceWithLabel = ({Device, deviceProps, tooltip = '', checked, onClick, label = '', children}) => {
  const classes = useStyles();
  return <Widget>
    <Tooltip title={tooltip} enterTouchDelay={0}>
      <FormControlLabel 
        control={<Device {...deviceProps} />}
        label={onlyText(children)} 
        onClick={onClick}
        checked={checked}
        style={ onClick || deviceProps.onChange ? {} : {cursor: 'default'} }
        classes={{root: classes.root}}
      />
    </Tooltip>
  </Widget>;
};

DeviceWithLabel.propTypes = {
  Device: PropTypes.elementType.isRequired,
  children: PropTypes.node.isRequired,
  deviceProps: PropTypes.object,
  tooltip: PropTypes.string,
  checked: PropTypes.bool,
  onClick: PropTypes.func,
}

export const deviceSpacingStyle = { width: ICON_SIZE, marginLeft: (42-ICON_SIZE)/2, marginRight: ((42-ICON_SIZE)/2)+8};

export const DeviceIconWithLabel = ({Icon, iconStyle, iconColor='action', htmlColor, iconClasses, iconProps, tooltip = '', onClick, children}) => {
  iconStyle = {fontSize: ICON_SIZE, ...deviceSpacingStyle, ...iconStyle};
  if(htmlColor) {
    iconColor = undefined;
  }
  return <DeviceWithLabel
            Device={Icon}
            deviceProps={{...iconProps, style: iconStyle, color: iconColor, classes: iconClasses, htmlColor}}
            onClick={onClick}
            tooltip={tooltip}
          >{children}</DeviceWithLabel>;
};

DeviceIconWithLabel.propTypes = {
  Icon: PropTypes.elementType.isRequired,
  children: PropTypes.node.isRequired,
  iconStyle: PropTypes.object,
  iconColor: PropTypes.oneOf(['action', 'disabled', 'error', 'inherit', 'primary', 'secondary']),
  htmlColor: PropTypes.string,
  iconClasses: PropTypes.object,
  iconProps: PropTypes.object,
  tooltip: PropTypes.string,
  checked: PropTypes.bool,
  onClick: PropTypes.func,
}

export const DefaultDeviceIconOnly = ({Icon, iconStyle, iconProps, tooltip = ''}) => {
  iconStyle = {fontSize: ICON_SIZE, ...iconStyle};
  return <Widget center>
    <Tooltip title={tooltip} enterTouchDelay={0}>
      <Icon {...iconProps} style={iconStyle} />
    </Tooltip>
  </Widget>;
};

DefaultDeviceIconOnly.propTypes = {
  Icon: PropTypes.elementType.isRequired,
  iconStyle: PropTypes.object,
  iconProps: PropTypes.object,
  tooltip: PropTypes.object,
}


export function useHighlighting(device) {
  const [highlighting, setHighlighting] = useState(false);
  const subsequent = useRef();
  
  useEffect(() => {
    if(subsequent.current) {
      setHighlighting(true);
      const timer = setTimeout(() => setHighlighting(false), WIDGET_HIGHLIGHT_DURATION);
      return () => clearTimeout(timer);
    } else {
      subsequent.current = true;
    }
  },[device.Data]);
  return highlighting;
}

export function isChecked(value) {
  return (value === 'Off' ? false : true)
}

export function useOwnState(newValue) {
  const [value, setValue] = useState(newValue);
  const prevValue = useRef();

  useEffect(() => {
    if(prevValue.current === value && value !== newValue) {
      setValue(newValue);
    }
    prevValue.current = value;
  },[newValue, value]);
  return [value, setValue];
}