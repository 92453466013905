import { createTheme, adaptV4Theme } from '@mui/material/styles';

export const VERSION = "v7.10.29";
export const TOOLBAR_HEIGHT = 48;
export const ICON_SIZE = 38;
// export const DOMOTICZ_REFRESH_INTERVAL = 1000; -- WebSockets now used
export const CAMERA_OFFLINE_RETRY = 10000;
export const CAMERA_PROGRESS_REFRESH_INTERVAL = 1000;
export const WIDGET_HIGHLIGHT_DURATION = 500;

export const DARKAPPTHEME = createTheme({
  palette: {
    mode: 'dark',
  },
  components: {
  // Style sheet name ⚛️
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          display: '-webkit-box', overflow: 'hidden', WebkitLineClamp: '2', WebkitBoxOrient: 'vertical',
        },
        root: {
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 100px #313235 inset',
          },
        },
      },
    },    
  },
});

export const LIGHTAPPTHEME = createTheme({
  components: {
  // Style sheet name ⚛️
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          display: '-webkit-box', overflow: 'hidden', WebkitLineClamp: '2', WebkitBoxOrient: 'vertical',
        },
        root: {
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 100px #fff inset',
          },
        },
      },
    },    
  },
});
/*
export const DARKAPPTHEME = createTheme(adaptV4Theme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    mode: 'dark',
    background: {
      default: '#1C1C1D',
      paper: '#313235',
    },
    primary: {
      light: '#96c7ff',
      main: '#5e97f6',
      dark: '#136ac3',
      contrastText: '#1C1C1D',
    },
    secondary: {
      light: '#e76b4c',
      main: '#dd2c00',
      dark: '#9a1e00',
      contrastText: '#1e1e1e',
    },
    text: {
      primary: "#F8F9FA",
    }
  },
  shape: {
    borderRadius: 8,
  },
  overrides: {
    // Style sheet name ⚛️
    MuiFormControlLabel: {
      // Name of the rule
      label: {
        display: '-webkit-box', overflow: 'hidden', WebkitLineClamp: '2', WebkitBoxOrient: 'vertical',
      },
      root: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    MuiOutlinedInput: {
      input: {
        '&:-webkit-autofill': {
          WebkitBoxShadow: '0 0 0 100px #313235 inset',
        },
      },
    },
  },
}));

export const LIGHTAPPTHEME = createTheme(adaptV4Theme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    mode: 'light',
    background: {
      default: '#fafafa',
      paper: '#FFF',
    },
    primary: {
      light: '#96c7ff',
      main: '#5e97f6',
      dark: '#136ac3',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#f1aa99',
      main: '#dd2c00',
      dark: '#581100',
      contrastText: '#ffffff',
    },
  },
  shape: {
    borderRadius: 8,
  },
  overrides: {
    // Style sheet name ⚛️
    MuiFormControlLabel: {
      // Name of the rule
      label: {
        display: '-webkit-box', overflow: 'hidden', WebkitLineClamp: '2', WebkitBoxOrient: 'vertical',
      },
      root: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    MuiOutlinedInput: {
      input: {
        '&:-webkit-autofill': {
          WebkitBoxShadow: '0 0 0 100px #fff inset',
        },
      },
    },
  },
}));
*/