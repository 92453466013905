import React from 'react'
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import { DeviceIconWithLabel } from './DefaultDevices';

export default function GenericDevice({device}) {
  return <DeviceIconWithLabel
   Icon={FormatQuoteIcon}
  >
    {device.Name}: {device.Data}
  </DeviceIconWithLabel>
}