import React from 'react';
import SmokeFreeIcon from '@mui/icons-material/SmokeFree';
import SmokingRoomsIcon from '@mui/icons-material/SmokingRooms';

import {DeviceIconWithLabel} from './DefaultDevices';

export default function SmokeDetector({device}) {
  return (
    <DeviceIconWithLabel
      Icon={device.Status === "Off" ? SmokeFreeIcon : SmokingRoomsIcon}
      iconColor={device.Status === "Off" ? "disabled" : "secondary"}
      tooltip={device.LastUpdate}
    >
      {device.Name}
    </DeviceIconWithLabel>
  );
}