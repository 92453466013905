import React from 'react';
import PropTypes from 'prop-types';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import Grid from '@mui/material/Grid';
import DomoticzDevice from './DomoticzDevice';
import CameraSnapshot from './CameraSnapshot';

const SortableDeviceItem = SortableElement(({device}) =>
  <DomoticzDevice    
    key={device.idx}
    device={device}
  />
)

export const SortableDeviceList = SortableContainer(({items, disabled}) => {
  return (<Grid container spacing={1} justifyContent={'center'}>
    {items.map((value, index) => (
      <SortableDeviceItem key={value.idx} index={index} device={value} disabled={disabled} />
    ))}
    </Grid>
  );
})

const SortableCameraItem = SortableElement(({camera, tabVisible}) => 
  <Grid item xs={12} sm={6}>
    <CameraSnapshot camera={camera} tabVisible={tabVisible} />
  </Grid>
);

export const SortableCameraList = SortableContainer(({items, disabled, tabVisible}) => {
  return (<Grid container spacing={0}>
    {items.map((value, index) => (
      <SortableCameraItem key={index} index={index} camera={value} tabVisible={tabVisible} disabled={disabled} />
    ))}
    </Grid>
  );
})

export function TabContainer({children, padding = '8px', margin = 1, ...otherProps }) {
  return (
    <div {...otherProps}>
      <div style={{padding, margin}}>
        {children}
      </div>
    </div>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  padding: PropTypes.string.isRequired,
  margin: PropTypes.number,
}
