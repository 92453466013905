import React, {useState, useEffect, useRef} from 'react';
import { Provider } from "react-redux";
import { arrayMoveImmutable } from 'array-move';
//import { isWidthDown } from '@mui/material/withWidth';
import CssBaseline from '@mui/material/CssBaseline';
import Fab from '@mui/material/Fab';
//import { MuiThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
//import { makeStyles,  } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Zoom from '@mui/material/Zoom';
import Slide from '@mui/material/Slide';
import Fade from '@mui/material/Fade';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import {useWidth, usingDarkTheme} from './util/HelperFunctions';
import AppTabToolbar from './AppTabToolbar';
import LoginDialog from './LoginDialog';
import CamDialog from './CamDialog';
import FavDialog from './FavDialog';
import ServiceWorkerModel from './ServiceWorkerModel';
import {DARKAPPTHEME, LIGHTAPPTHEME, TOOLBAR_HEIGHT} from './Constants';
import {TabContainer, SortableDeviceList, SortableCameraList} from './sortableTabs';
import {store} from './devicesStore';
import useDomoticzConnection from './DomoticzConnection';

/* TODO
https://github.com/drcmda/react-spring <-- voor animaties
* Meer spacing bij grote displays en welliocht andere schermindeling
* meer bewegingen
* opmaak of allemaal inline of via theme css
* on extit fullscreen camera .. exit 1-camera view 
*/

// console.logs = [];
// console.stdlog = console.log.bind(console);
// console.log = function(){
//     console.logs.push(Array.from(arguments));
//     console.stdlog.apply(console, arguments);
// }


const useStyles = makeStyles(theme => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  fabMoveUp: {
    transform: 'translate3d(0, -52px, 0)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.easeOut,
    }),
  },
  fabMoveDown: {
    transform: 'translate3d(0, -52px, 0)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp,
    }),
  },
  gettingSorted: {
    filter: 'drop-shadow(0 0 4px '+theme.palette.primary.light+')',
  },
  '@global': {
    body: {
      overflowX: 'hidden',
      userSelect: 'none',
    },
  },
  loader: {
    position: 'fixed !important',
    bottom: 0,
    left: 0,
    width: '100%',
    height: 2,
    zIndex: 1100,
  },
  loading: {
    position: 'fixed',
    overflow: 'visible',
    margin: 'auto',
    width: 'fit-content',
    height: 'fit-content',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    color: "rgba(119,119,119, 0.54)",
  },
}));

const theme = createTheme();


export default function AppTheme({children}) {
  const sunSetRise = useRef();
  const [darkTheme, setDarkTheme] = useState(() => {
    const lockedDarkTheme = usingDarkTheme();
    //if(!lockedDarkTheme) {
      setInterval(() => {
        updateSunset();
      }, 60000);
   // }
    return lockedDarkTheme;
  });

  function updateSunset() {
    const lockedDarkTheme = usingDarkTheme();
    if(!lockedDarkTheme) {
      if(!sunSetRise.current)
        return;
      const [sunset, sunrise] = sunSetRise.current;
      if(sunset && sunrise) {
        const now = new Date();
        let startSunset = new Date();
        startSunset.setHours(sunset.substring(0,2), sunset.substring(3,5),0);
        let endSunset = new Date();
        endSunset.setDate(endSunset.getDate() + 1);
        endSunset.setHours(sunrise.substring(0,2), sunrise.substring(3,5),0);
        setDarkTheme(now >= startSunset && now < endSunset);
      }
    } else {
      setDarkTheme(true);
    }
  }

  //return  <ThemeProvider theme={theme}>{children}</ThemeProvider>;
  return  <ThemeProvider theme={darkTheme ? DARKAPPTHEME : LIGHTAPPTHEME}><App sunSetRise={sunSetRise}>{children}</App></ThemeProvider>;
}

function App({sunSetRise}) {
  const [allDevices, setAllDevices] = useState([]);
  const [favDevices, setFavDevices] = useState([]);
  const [otherDevices, setOtherDevices] = useState([]);
  const [cameras, setCameras] = useState(() => {
    let myCameras = localStorage.cameras;
    if(myCameras) {
      try { myCameras = JSON.parse(myCameras); } 
      catch(e) { myCameras = []; }
    } else {
      myCameras = [];
    }
    return myCameras;
  });
  const prevTab = useRef(-1);
  const tabNames = ['dash', 'cams', 'favs'];
  let {username, password } = localStorage;
  const [{loggedIn, loginError, offline}, setServerAuth, gotAllDevices] = useDomoticzConnection([username,password], sunSetRise);



  const [changingOrder, setChangingOrder] = useState(false);
  const [camDialog, setCamDialog] = useState(false);
  const [showCameraTab, setShowCameraTab] = useState(() =>{
    let myShowCameraTab = localStorage.showCameraTab;
    if(typeof myShowCameraTab === 'undefined') {
      myShowCameraTab = true;
    } else {
      myShowCameraTab = myShowCameraTab === 'true';
    }
    return myShowCameraTab;
  });
  //const tabNames = ['dash', 'cams', 'favs'];
  const [tab, setTab] = useState(() => {
    let {lastTab} = localStorage;
    let tabUrl = tabNames.indexOf(window.location.pathname.substring(1));
    if(tabUrl >= 0) {
      lastTab = tabUrl;
    } else {
      if(typeof lastTab === 'undefined') {
        lastTab = 0;
      } else {
        lastTab = JSON.parse(lastTab);
      }
    }
    return lastTab;
  });
  const [favDialog, setFavDialog] = useState(false);
  const [snackbarActive, setSnackbarActive] = useState(false);

  useEffect(() => {
    window.history.replaceState(null, "", "/"+tabName);
    localStorage.setItem("lastTab", JSON.stringify(tab));
    prevTab.current = tab;
  },[tab]);
  
  useEffect(() => {
    if(gotAllDevices) {
      //if(!usingDarkTheme()) 
      //TODO updateSunset();
      createFilteredDevices();
    }
  },[gotAllDevices]);
  
  function createFilteredDevices() {

    function sortDevicesBySavedOrder(savedOrder, devices) {
      let sort = function(deviceA, deviceB) {
        let deviceA_index;
        let deviceB_index;

        if(savedOrder) {
          deviceA_index = savedOrder.indexOf(deviceA.idx);
          deviceB_index = savedOrder.indexOf(deviceB.idx);
        } else {
          deviceA_index = deviceA.Name;
          deviceB_index = deviceB.Name;
        }

        if (deviceA_index < deviceB_index) { return -1; }
        if (deviceA_index > deviceB_index) { return 1; }
        return 0;
      }
      return devices.sort(sort);
    }
    
    const myDevices = store.getState();
    const myFavOrder = JSON.parse(localStorage.getItem("favOrder")) || [];
    const myOtherOrder = JSON.parse(localStorage.getItem("otherOrder")) || [];
    const myAllDevices = sortDevicesBySavedOrder(false,myDevices.filter((device)=>device.Favorite !== 1));
    const myFavDevices = sortDevicesBySavedOrder(myFavOrder, myDevices.filter((device)=>device.Favorite === 1));
    

    
    const myOtherDevices = sortDevicesBySavedOrder(myOtherOrder, myDevices.filter((device) => myOtherOrder.indexOf(device.idx) > -1));
    const idxFilter = (device) => ({idx: device.idx});
    setFavDevices(myFavDevices.map(idxFilter));
    setOtherDevices(myOtherDevices.map(idxFilter));
    setAllDevices(myAllDevices.map(idxFilter));
  }
 
  function onSortEnd(arrayName, {oldIndex, newIndex}) {
    switch(arrayName) {
      case 'otherDevices': 
        setOtherDevices(arrayMoveImmutable(otherDevices, oldIndex, newIndex));
        break;
      case 'favDevices': 
        setFavDevices(arrayMoveImmutable(favDevices, oldIndex, newIndex));
        break;
      case 'cameras': 
        setCameras((arrayMoveImmutable(cameras, oldIndex, newIndex)));
        break;
      default:
    }
  }

  const classes = useStyles();
  const theme = useTheme();
  const width = useWidth();
  const fabClassName = snackbarActive && true /*isWidthDown('sm', width) TODO */? classes.fabMoveUp : classes.fabMoveDown;
  
  const tabName = tabNames[tab];
  return (
    <Provider store={store}>
      <CssBaseline />
      {(!loggedIn || !gotAllDevices) && <div className={classes.loading}>{"Loading..."}</div>}
      <Box sx={{ pb: 7 }}>
        <Fade in={loggedIn && gotAllDevices} mountOnEnter>
          <div>
            <Slide appear={false} direction="right" in={tabName === 'dash'} mountOnEnter>
              <div style={{display: tabName !== 'dash' ? "none" : "block"}}>
              <TabContainer padding={theme.spacing(1)}>
                <SortableDeviceList 
                  axis="xy" 
                  helperClass={classes.gettingSorted}
                  items={favDevices} 
                  onSortEnd={onSortEnd.bind(this, "favDevices")}
                  disabled={!changingOrder}
                  shouldCancelStart={() => false}
                />
              </TabContainer>
              </div>
            </Slide>
            <Slide appear={false}  direction={prevTab.current ? "right" : "left"} in={tabName === 'cams'} mountOnEnter>
              <div style={{display: tabName !== 'cams' ? "none" : "block"}} >
                <TabContainer padding={'0px'} margin={0}>
                  <SortableCameraList 
                    axis="xy"
                    helperClass={classes.gettingSorted}
                    items={cameras} 
                    onSortEnd={onSortEnd.bind(this, "cameras")}
                    disabled={!changingOrder}
                    shouldCancelStart={() => false}
                    tabVisible={tabName === 'cams'}
                  />
                </TabContainer>
              </div>
            </Slide>
            <Slide appear={false}  direction="left" in={tabName === 'favs'} mountOnEnter>
              <div style={{display: tabName !== 'favs' ? "none" : "block"}} >
              <TabContainer padding={theme.spacing(1)}>
                <SortableDeviceList 
                  axis="xy" 
                  helperClass={classes.gettingSorted}
                  items={otherDevices} 
                  onSortEnd={onSortEnd.bind(this, "otherDevices")}
                  disabled={!changingOrder}
                  shouldCancelStart={() => false}
                />
              </TabContainer>
              </div>
            </Slide>
            <Zoom in={tabName === 'favs' && !changingOrder}>
              <div className={classes.fab}>
                <Fab className={fabClassName} color={'secondary'} onClick={toggleFavDialog}>
                  <AddIcon />
                </Fab>
              </div>
            </Zoom>
            <Zoom in={changingOrder}>
              <div className={classes.fab}>
                <Fab className={fabClassName} color={'primary'} onClick={handleSaveFabClick}>
                  <SaveIcon />
                </Fab>
              </div>
            </Zoom>
            <Zoom in={tabName === 'cams' && cameras.length === 0 && !changingOrder}>
              <div className={classes.fab}>
                <Fab className={fabClassName} color={'secondary'} onClick={toggleCamDialog}>
                  <AddIcon />
                </Fab>
              </div>
            </Zoom>
          </div>
        </Fade>
        <LoginDialog
          open={(!loggedIn && !username && !password) || loginError}
          onLogin={handleLoginDialogSubmit}
          error={loginError}
        />
        <CamDialog
          open={camDialog}
          onSubmit={handleCamDialogSubmit}
          onCancel={toggleCamDialog}
          cameras={cameras}
          showCameraTab={showCameraTab}
          onHideTab={handleCamDialogHideTab}
        />
        <FavDialog
          open={favDialog}
          onClose={toggleFavDialog}
          allDevices={allDevices}
          selectFavHandler={selectFavHandler}
          checkedFavHandler={checkedFavHandler}
        />
        <AppTabToolbar 
          loggedIn={loggedIn}
          handleMenuItemClick={handleMenuItemClick}
          changingOrder={changingOrder}
          showCameraTab={showCameraTab}
          tab={tab}
          setTab={setTab}
        />
        <Fade
          in={offline && loggedIn }
          timeout={{enter: 1500, exit: 1500}}
          mountOnEnter
          unmountOnExit
        >
          <LinearProgress color="secondary" variant="query" className={classes.loader} />
        </Fade>      
        <ServiceWorkerModel snackbarActive={snackbarActive} onShow={snackbarActive => setSnackbarActive(snackbarActive)} />
      </Box>
    </Provider>
  );
  
  function selectFavHandler(device, selected) {
    let myOtherDevices = otherDevices.concat();
    if(selected) {
      myOtherDevices.push(device);
    } else {
      let index = myOtherDevices.map((device) => device.idx).indexOf(device.idx);
      if(index > -1) {
        myOtherDevices.splice(index, 1);
      }
    }
    let myOtherOrder = myOtherDevices.map((device) => device.idx);
    localStorage.setItem("otherOrder", JSON.stringify(myOtherOrder)); 
    setOtherDevices(myOtherDevices);
  };

  function checkedFavHandler(device) {
    return otherDevices.map((device) => device.idx).indexOf(device.idx) > -1;
  };

  function handleSaveFabClick() {
    setChangingOrder(false);
    
    const myFavOrder = favDevices.map((device) => device.idx);
    localStorage.setItem("favOrder", JSON.stringify(myFavOrder));
    
    const myOtherOrder = otherDevices.map((device) => device.idx);
    localStorage.setItem("otherOrder", JSON.stringify(myOtherOrder));
    
    localStorage.setItem("cameras", JSON.stringify(cameras));
  }
  
  function handleMenuItemClick(event) {
    switch(event.target.id) {
      case "logout" :            
        setAllDevices([]);
        setFavDevices([]);
        setOtherDevices([]);
        localStorage.removeItem('username');
        localStorage.removeItem('password');
        setServerAuth(false);
        break;
      case "changeOrder" :
        if(changingOrder) {
          createFilteredDevices(); // reset order if not saved
        }
        setChangingOrder(!changingOrder);
        break;
      case "cameras" :
        toggleCamDialog();
        break;
      case "version" :
//         alert(console.logs.join('\n'));
//         console.logs.length = 0;
        break;
      default:
    }
  };
  
  function handleLoginDialogSubmit(loginConfig) {
    const md5 = require('md5');
    let { username, password} = loginConfig;
    username = btoa(username);
    password = md5(password);
    localStorage.username = username;
    localStorage.password = password;
    setServerAuth([username,password]);
  };

  function toggleCamDialog() {
    setCamDialog(visible => !visible);
  };
  
  function toggleFavDialog() {
    setFavDialog(visible => !visible);
  };

  function handleCamDialogSubmit(myCams) {
    localStorage.setItem("cameras", JSON.stringify(myCams));
    setCameras(myCams);
    toggleCamDialog();
  };
  
  function handleCamDialogHideTab() {
    const myShowCameraTab = !showCameraTab;
    localStorage.setItem("showCameraTab", myShowCameraTab);
    if(!myShowCameraTab && tab == 1) setTab(0);
    setShowCameraTab(myShowCameraTab);
  }
}