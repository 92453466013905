import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@mui/styles';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Tooltip from '@mui/material/Tooltip';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import useMediaQuery from '@mui/material/useMediaQuery';

const wiggle = {
  '0%': {transform: 'rotate(10deg)'},
  '25%': {transform: 'rotate(-10deg)'},
  '50%': {transform: 'rotate(20deg)'},
  '75%': {transform: 'rotate(-5deg)'},
  '100%': {transform: 'rotate(0deg)'},
};

const waves = {
  'from': {transform: 'rotate(0deg)'},
  'to': {transform: 'rotate(360deg)'},
};

const useStyles = makeStyles(theme => ({
  PrepareForWaves: {
    overflow: 'hidden !important',
  },
  StayAboveWaves: {
    zIndex: 1,
  },
  Wiggle: {
    animation: '$wiggle 1s',
    animationTimingFunction: 'ease-in-out',
    animationDelay: '1s',
    '&:hover': {
      animation: '$hoverWiggle 1s',
      animationTimingFunction: 'ease-in-out',
    }
  },
  '@keyframes wiggle': wiggle,
  '@keyframes hoverWiggle': wiggle,
  '@keyframes waves': waves,
  Wave: {
    position: 'absolute',
    width: '600px',
    height: '600px',
    borderRadius: '55% 45% 50% 40%',
  },
  Wave1: {
    right: '-300px',
    bottom: '-400px',
    backgroundColor: theme.palette.primary.light,
    opacity: '20%',
    animation: '$waves 10s infinite linear',
    animationDelay: '1s',
  },
  Wave2: {
    right: '-350px',
    bottom: '-450px',
    backgroundColor: theme.palette.primary.main,
    opacity: '20%',
    animation: '$waves 9s infinite linear',
  },
}));


export default function LoginDialog({open,onLogin,error}) {
  
  const [username,setUsername] = useState("");
  const [password,setPassword] = useState("");

  function handleChange(event) {
    const value = event.target.value;
    switch(event.target.id) {
      case "username": 
        setUsername(value);
        break;
      case "password":
        setPassword(value);
        break;
      default:
    }
  };
  
  function handleLogin(e) {  
    if(e) e.preventDefault();
    if(username && password) {
      onLogin({username, password});
    }
  };
  
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog 
      fullScreen={fullScreen}
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="login-dialog-title"
      open={open}
      classes={{paper: classes.PrepareForWaves}}
    >
      <p className={[classes.Wave, classes.Wave1].join(' ')} />
      <p className={[classes.Wave, classes.Wave2].join(' ')} />
      <DialogTitle id="login-dialog-title" classes={{root: classes.StayAboveWaves}}>Login to Akerboom.app
        <Tooltip enterTouchDelay={0} title={error ? "Error logging in, please try again" : "Please provide a valid username/password and press login"}>
          <VpnKeyIcon 
            className={classes.Wiggle} 
            style={{position: "absolute", right: 24}} 
            color={error ? "error" : "disabled"} 
            fontSize="large"
          />
        </Tooltip>
      </DialogTitle>
      <form onSubmit={handleLogin}>
        <DialogContent>
          <TextField
            classes={{root: classes.StayAboveWaves}}
            required
            fullWidth
            id="username"
            label="Username"
            autoComplete="username"
            margin="dense"
            onChange={handleChange}
            error={error}
            variant="outlined"
          />
          <TextField
            required
            fullWidth
            id="password"
            label="Password"
            type="password"
            autoComplete="current-password"
            margin="dense"
            onChange={handleChange}
            error={error}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit" disabled={password === "" || username === ""}  autoFocus>
            Login
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

LoginDialog.propTypes = {
  onLogin: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
};